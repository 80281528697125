.root {
  @apply inline-flex text-center items-center leading-7;
}

.root .dot {
  @apply rounded-full h-2 w-2;
  background-color: currentColor;
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  margin: 0 2px;
}

.root .dot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.root .dot::nth-of-type(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
