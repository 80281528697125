.root {
  @apply cursor-pointer inline-flex
  p-3  transition ease-in-out duration-150
  shadow-sm rounded-sm text-center justify-center 
  border border-transparent items-center 
  font-roboto font-normal text-label text-white
  bg-ui-darkest-grey
  tracking-wide;
}

.root:hover {
  @apply bg-ui-dark-grey;
}

.root:focus {
  @apply shadow-outline-normal outline-none;
}

.root:disabled {
  @apply bg-ui-grey-25 cursor-not-allowed;
}

.root[data-active] {
  @apply bg-accent-6;
}

.secondary {
  @apply bg-white border-ui-darkest-grey text-ui-dark-grey;
}

.secondary:hover {
  @apply bg-ui-dark-grey border-ui-dark-grey text-white;
}

.secondary:disabled {
  @apply bg-transparent text-ui-grey-50 border-ui-grey-25;
}

.loading {
  @apply cursor-not-allowed;
}

.slim {
  @apply py-1 transform-none normal-case;
}

.ghost {
  @apply border-transparent bg-transparent text-white outline-none shadow-none;
}

.ghost:hover {
  @apply text-ui-dark-grey bg-transparent;
}

.ghost:disabled {
  @apply text-ui-grey-50 border border-ui-grey-25 bg-transparent;
}

.naked {
  @apply bg-white text-dark-grey border-none shadow-none outline-none;
}

.naked:hover,
.naked:focus {
  @apply bg-white border-none;
}
.naked:disabled {
  @apply bg-ui-grey-25 text-ui-white;
}

/* .disabled,
.disabled:hover {
  @apply text-accent-4 border-accent-2 bg-accent-1 cursor-not-allowed;
  filter: grayscale(1);
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
} */

.progress {
}
