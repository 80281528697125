.root {
  @apply fixed inset-0 box-border h-full outline-none;
  z-index: 2147483646;
}

.sidebar {
  @apply flex h-full flex-col overflow-y-auto overflow-x-hidden text-base;
  -webkit-overflow-scrolling: touch !important;
}

.backdrop {
  @apply absolute inset-0 bg-black bg-opacity-40 duration-100 ease-linear;
  backdrop-filter: blur(0.8px);
  -webkit-backdrop-filter: blur(0.8px);
}
