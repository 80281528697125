.root {
  @apply flex flex-col pt-6;
}

.root:first-child {
  padding-top: 0;
}

.root:last-child {
  border-bottom-width: 1px !important;
  @apply pb-6;
}

.quantity {
  appearance: textfield;
  @apply mx-3 w-8 rounded border border-accent-2 text-center text-sm text-black;
}

.quantity::-webkit-outer-spin-button,
.quantity::-webkit-inner-spin-button {
  @apply m-0 appearance-none;
}

.productImage {
  position: absolute;
  transform: scale(1.9);
  left: 30% !important;
  top: 30% !important;
  z-index: 1;
}

.productName {
  @apply cursor-pointer text-body2 font-medium text-brand-dark-grey;
}
