.root {
  @apply sticky top-0 z-40 transition-all duration-150;
  min-height: 56px;
}

.nav {
  @apply relative flex flex-row justify-between py-9 md:py-4;
}

.navMenu {
  @apply ml-6 hidden space-x-4 lg:block;
}

.link {
  @apply inline-flex cursor-pointer items-center
  leading-6 text-accent-5 transition duration-75
  ease-in-out;
}

.link:hover {
  @apply text-accent-9;
}

.link:focus {
  @apply text-accent-8 outline-none;
}

.logo {
  @apply transform cursor-pointer rounded-full border duration-100 ease-in-out;

  &:hover {
    @apply shadow-md;
    transform: scale(1.05);
  }
}
