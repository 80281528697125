.root {
  min-height: 100vh;
}

.root.empty {
  @apply bg-secondary text-secondary;
}


.lineItemsList {
  @apply space-y-6 divide-y  divide-ui-grey-25 py-0;
}
