.root {
  @apply relative h-full flex flex-col;
}

.header {
  @apply sticky top-0 pl-4 py-4 pr-6
  flex items-center justify-between 
  bg-brand-light-grey box-border w-full z-10;
  min-height: 56px;
}

.container {
  @apply flex flex-col flex-1 box-border;
}

@screen lg {
  .header {
    min-height: 72px;
  }
}
