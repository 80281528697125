.root {
  @apply relative flex items-center;
}

.list {
  @apply flex flex-row items-center justify-items-end h-full;
}

.item {
  @apply ml-6 cursor-pointer relative transition ease-in-out
  duration-100 flex items-center outline-none text-primary;
}

.item:hover {
  @apply text-accent-6 transition scale-110 duration-100;
}

.item:first-child {
  @apply ml-0;
}

.item:focus,
.item:active {
  @apply outline-none;
}

.circle {
  @apply absolute overflow-hidden indent-4;
  top: -2px;
  right: -2px;
  background-color: inherit;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  display: inline-block;
}
