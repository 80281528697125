.actions {
  @apply flex items-center justify-center text-brand-dark-grey font-normal text-body2;
  transition-property: border-color, color, transform, box-shadow;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  user-select: none;
}

.actions:hover {
  @apply opacity-75;
  transition: border-color;
  z-index: 10;
}

.actions:focus {
  @apply outline-none;
}

.actions:disabled {
  @apply cursor-not-allowed;
}

.input {
  @apply bg-transparent px-3 text-brand-dark-grey font-normal text-body2 focus:outline-none select-none pointer-events-auto;
}
